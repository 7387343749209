import {
  Chart,
  CommonSeriesSettings,
  Series,
  ValueAxis,
  ArgumentAxis,
  Label,
  Export,
  Format,
  Grid,
  Tooltip,
  Legend,
  Size,
  Title,
} from "devextreme-react/chart";
import { useFetch } from "src/hooks/useFetch";
import { SpatialRun } from "../types";
import { useMemo } from "react";
import { Typography } from "@mui/material";

export enum PlotType {
  CellAssignedCounts,
  CellAssignedRate,
  CellPolonyDropoutRate,
}

const CytoCellAssigned = ({
  run,
  type,
}: {
  run: SpatialRun;
  type: PlotType;
}) => {
  const {
    data: rawData,
    isLoading,
    refetch,
    isError,
    error,
  } = useFetch(`/spatialRun/${run?.runID}/cellAssigned`, !!run?.runID);

  const field =
    type === PlotType.CellAssignedCounts
      ? "assignedCountsPerMM2"
      : type === PlotType.CellAssignedRate
      ? "cellularAssignedRate"
      : "cellularPolonyDropOutRate";

  const title =
    type === PlotType.CellAssignedCounts
      ? "Cell Assigned Counts"
      : type === PlotType.CellAssignedRate
      ? "Cell Assigned Rate"
      : "Cell Polony Dropout Rate";

  const ylabel =
    type === PlotType.CellAssignedCounts
      ? "Counts Per MM2"
      : type === PlotType.CellAssignedRate
      ? "Rate"
      : "Rate";

  const data = (rawData as {}[])?.map((v, i) => {
    const obj = {
      Well: run.wells[i].WellLocation,
    };
    for (const key in v) {
      obj[key] = v[key][field];
    }

    return obj;
  });

  console.log("data", data);

  const dataAveraged = useMemo(() => {
    if (!data) return { wellAverages: [], runAverage: NaN };
    const averagedData: { value: number; well: string }[] = [];
    const batches = run.batches.filter((b) => b.PMGMask);
    let totalCount = 0;
    let totalSum = 0;
    run.wells.forEach((well, i) => {
      const wellData = data.find((d) => d.Well === well.WellLocation);
      if (wellData) {
        // wellData is now on object where the fields are the batch names and the values are the counts
        // we want to average the counts
        let sum = 0;
        let count = 0;
        batches.forEach((b) => {
          const value = wellData[b.Name];
          if (value !== undefined) {
            sum += value;
            totalSum += value;
            count++;
            totalCount++;
          }
        });
        let val = NaN;
        if (count > 0) {
          val = sum / count;
        }
        averagedData.push({ value: val, well: well.WellLocation });
      }
    });
    return {
      wellAverages: averagedData,
      runAverage: totalCount > 0 ? totalSum / totalCount : NaN,
    };
  }, [data, run?.batches, run?.wells]);

  return (
    <>
      <Typography
        variant="h6"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        Run Average{" "}
        {type === PlotType.CellAssignedCounts
          ? "Cell Assigned Counts"
          : type === PlotType.CellAssignedRate
          ? "Cell Assigned Rate"
          : "Cell Polony Dropout Rate"}
        : {dataAveraged.runAverage.toFixed(0)}{" "}
        {type === PlotType.CellAssignedCounts
          ? "Counts Per MM2"
          : type === PlotType.CellAssignedRate
          ? ""
          : ""}
      </Typography>

      <Chart dataSource={data}>
        <Title
          horizontalAlignment="center"
          text={`${run?.runName} ${title} By Well and Batch`}
        />
        <Size width="100%" />
        <CommonSeriesSettings argumentField="Well" type="bar" barPadding={0} />
        {run?.batches
          ?.filter((b) => b.PMGMask)
          .map((b) => (
            <Series key={b.Name} valueField={b.Name} name={b.Name} />
          ))}
        <ValueAxis title={ylabel}>
          <Grid visible={true} />
        </ValueAxis>
        <ArgumentAxis title="Well">
          <Label wordWrap="none" overlappingBehavior="stagger" />
        </ArgumentAxis>
        <Legend
          verticalAlignment="top"
          horizontalAlignment="right"
          // position={"inside"}
        ></Legend>
        <Export enabled />
        <Tooltip enabled>
          <Format type="fixedPoint" precision={1} />
        </Tooltip>
      </Chart>

      <Chart
        dataSource={dataAveraged.wellAverages}
        style={{ marginTop: "60px" }}
      >
        <Title
          horizontalAlignment="center"
          text={`${run?.runName} Average ${title} By Well`}
        />
        <Size width="100%" />
        <Series
          argumentField="well"
          valueField="value"
          type="bar"
          barPadding={0}
        />
        <ValueAxis title={"Average " + ylabel}>
          <Grid visible={true} />
        </ValueAxis>
        <ArgumentAxis title="Well">
          <Label wordWrap="none" overlappingBehavior="stagger" />
        </ArgumentAxis>
        <Export enabled />
        <Tooltip enabled>
          <Format type="fixedPoint" precision={1} />
        </Tooltip>
      </Chart>
    </>
  );
};

export default CytoCellAssigned;
