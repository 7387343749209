import { FC, memo, useState, useEffect } from "react";
import { CheckBox, NumberBox, SelectBox } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";

import { FlowcellChart } from "../../../components";
import { ColorRangeBar } from "../../../components";
import { decompressFloat32Array } from "src/utils/decompress";
import { ProdStatsType, TilesDataType } from "./run-container";

interface RunTileCycleProps {
  data: ProdStatsType;
  isLoading: boolean;
  reads: { read: string; cycles: number }[];
}

const metricList = [
  "Q15",
  "Q15All",
  "Q30",
  "Q40",
  "Q50",
  "Error",
  "Intensity",
  "Background",
  "FWHM",
  "Phasing",
  "Prephasing",
];

const hasChannelData = (metric: string): boolean => {
  return ["Intensity", "Background", "FWHM", "Phasing", "Prephasing"].includes(
    metric
  );
};

const channelList = [1, 2, 3, 4];

const RunTileCycle: FC<RunTileCycleProps> = memo(
  ({ data, reads, isLoading }: RunTileCycleProps) => {
    const [range, setRange] = useState({ min: 0, max: 100 });
    const [defaultRange, setDefaultRange] = useState(null);
    const [fixScale, setFixScale] = useState(false);
    const [cycle, setCycle] = useState(1);
    const [channel, setChannel] = useState(1);
    const [read, setRead] = useState(
      reads?.find((v) => v.read === "R1") ? "R1" : ""
    );

    const [dataArray, setDataArray] = useState([]);
    const [flowcellData, setFlowcellData] = useState([]);
    const [tileLabels, setTileLabels] = useState([]);
    const [metric, setMetric] = useState("Q30");

    useEffect(() => {
      if (!data) return;
      const pst = data as ProdStatsType;
      const drt = pst?.tiles;
      if (drt && drt.rawPolonyCounts) {
        setTileLabels(drt.tileLabels);
        let str = "";
        switch (metric) {
          case "Q15":
            str = drt.q15ByCycle;
            break;
          case "Q15All":
            str = drt.q15AllByCycle;
            break;
          case "Q30":
            str = drt.q30ByCycle;
            break;
          case "Q40":
            str = drt.q40ByCycle;
            break;
          case "Q50":
            str = drt.q50ByCycle;
            break;
          case "Error":
            str = drt.errorByCycle;
            break;
          case "Intensity":
            str = drt.intensityByCycle;
            break;
          case "Background":
            str = drt.backgroundByCycle;
            break;
          case "FWHM":
            str = drt.fwhmByCycle;
            break;
          case "Phasing":
            str = drt.phasingByCycle;
            break;
          case "Prephasing":
            str = drt.prephasingByCycle;
            break;
        }
        let vals = decompressFloat32Array(str);
        setDataArray(vals);
      }
    }, [data, metric]);

    useEffect(() => {
      if (!dataArray || dataArray.length === 0 || !read || !cycle) {
        setFlowcellData([]);
        return;
      }

      const hasChannels = hasChannelData(metric);

      let minv = 999999;
      let maxv = -999999;

      const numTiles = tileLabels.length;

      let baseCycle = 0;
      const tmp = ["R1", "R2", "I1", "I2"]; // apparently always hardcoded in this order
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i] === read) break;
        baseCycle += reads.find((v) => v.read === tmp[i])?.cycles || 0;
      }

      const absoluteCycle = baseCycle + cycle - 1;

      const fac = hasChannels ? 4 : 1;
      const start =
        absoluteCycle * numTiles * fac +
        (hasChannels ? (channel - 1) * numTiles : 0);

      const vals = [];

      for (let i = start; i < start + numTiles; i++) {
        const value = dataArray[i];
        if (value < minv) minv = value;
        if (value > maxv) maxv = value;
        vals.push(value);
      }
      if (minv > maxv) {
        minv = 0;
        maxv = 100;
      } else if (minv === maxv) {
        minv = minv * 0.9;
        maxv = maxv * 1.1;
      }
      setFlowcellData(vals);
      if (!fixScale) {
        setRange({ min: minv, max: maxv });
        setDefaultRange({ min: minv, max: maxv });
      }
    }, [dataArray, fixScale, tileLabels, cycle, read, channel, reads, metric]);

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            marginTop: 30,
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: "#parentDiv" }}
            visible={isLoading}
            showIndicator={true}
            shading={true}
            // showPane={true}
          />

          <br />

          <div style={{ display: "flex", marginLeft: "20px", marginBottom: 5 }}>
            <span style={{ paddingTop: 8 }}>Metric:&nbsp;&nbsp;&nbsp;</span>

            <SelectBox
              value={metric}
              placeholder="Select a metric..."
              showClearButton={false}
              dataSource={metricList}
              onValueChanged={(e) => setMetric(e.value)}
              style={{ width: "190px" }}
            />
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", marginLeft: "20px" }}>
              <span style={{ paddingTop: 8 }}>Read:&nbsp;&nbsp;&nbsp;</span>

              <SelectBox
                value={read}
                placeholder="Select a read..."
                showClearButton={false}
                dataSource={reads.map((v) => v.read)}
                onValueChanged={(e) => {
                  setRead(e.value);
                  setCycle(1);
                }}
                style={{ width: "100px" }}
              />
            </div>

            <div style={{ display: "flex", marginLeft: "20px" }}>
              <span style={{ paddingTop: 8 }}>Cycle:&nbsp;&nbsp;&nbsp;</span>
              <NumberBox
                style={{ width: "100px", paddingLeft: "15px" }}
                min={1}
                max={reads.find((v) => v.read === read)?.cycles || 1}
                showSpinButtons
                value={cycle}
                onValueChanged={(e) => {
                  setCycle(e.value);
                }}
              />
            </div>

            <div
              style={{
                display: hasChannelData(metric) ? "flex" : "none",
                marginLeft: "20px",
              }}
            >
              <span style={{ paddingTop: 8 }}>Channel:&nbsp;&nbsp;&nbsp;</span>
              <SelectBox
                value={channel}
                placeholder="Select a channel..."
                showClearButton={false}
                dataSource={channelList}
                onValueChanged={(e) => setChannel(e.value)}
                style={{ width: "100px" }}
              />
            </div>
          </div>

          <div
            id="parentDiv"
            style={{
              marginTop: "5px",
              display: "flex",
              width: "400px",
              height: "500px",
            }}
          >
            {flowcellData && flowcellData.length > 0 && (
              <>
                <div style={{ width: "160px", height: "500px" }}>
                  <FlowcellChart
                    data={flowcellData}
                    labels={tileLabels}
                    minv={range.min}
                    maxv={range.max}
                    title="S1"
                    tooltipprecision={0}
                    hidelegend
                  />
                </div>{" "}
                <div style={{ width: "160px", height: "500px" }}>
                  <FlowcellChart
                    data={flowcellData}
                    labels={tileLabels}
                    minv={range.min}
                    maxv={range.max}
                    title="S2"
                    tooltipprecision={0}
                    hidelegend
                  />
                </div>
                <div
                  style={{
                    width: "100px",
                    display: defaultRange ? "" : "none",
                  }}
                >
                  <ColorRangeBar
                    minv={defaultRange?.min}
                    maxv={defaultRange?.max}
                    rangeChanged={(r) => setRange(r)}
                  />
                </div>
              </>
            )}
          </div>

          <CheckBox
            text="Fix Scale"
            value={fixScale}
            onValueChanged={(e) => {
              setFixScale(e.value);
            }}
          />
        </div>
      </div>
    );
  }
);

export default RunTileCycle;
